/*
* 获取webview打开的平台
*/
const u = window.navigator.userAgent.toLowerCase();

const platform = {
    isIOSBangs() { // X XS, XS Max, XR,11,11Pro,11Pro Max
        const xSeriesConfig = [
            {
                devicePixelRatio: 3,
                width: 375,
                height: 812,
            },
            {
                devicePixelRatio: 3,
                width: 414,
                height: 896,
            },
            {
                devicePixelRatio: 2,
                width: 414,
                height: 896,
            },
        ]; // h5

        if (typeof window !== 'undefined' && window) {
            const isIOS = /iphone/gi.test(window.navigator.userAgent);
            if (!isIOS) return false;
            const {
                devicePixelRatio,
                screen
            } = window;
            const {
                width,
                height
            } = screen;
            const flag = xSeriesConfig.some(item => item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height);
            return flag;
        }

        return false;
    },
    isWeChat() {
        return /(micromessenger)/i.test(u);
    },
    isAndroid() {
        return u.indexOf('android') > 0 ? true : false;
    },
    isIOS() {
        return /iphone|ipad|ipod|ios/.test(u);
    },
    // 观海webview
    isGhAnWeb() {
        const refer = window.localStorage.getItem('refer');
        return refer == 'android' ? true : false
    },
    isGhIosWeb() {
        return /ai-live-ios/.test(u);
    },
    // 海尔人webview
    isHbiOSWeb() {
        return /ios-/.test(u);
    },
    isHbandroid() {
        return /android-/.test(u);
    },
    // 海尔人windows端
    isHbWindows() {
        return /seaside/.test(u);
    },
	// iHaier环境
	isIHaier() {
		return /lark/.test(u);
    },
    isIHaierMobile() {
		return /lark/.test(u) && /android|iphone/.test(u)
	},
    isIHaierMac() {
        return /mac/.test(u)&&!/iphone/.test(u);
    },
    isIHaierWindows() {
        return /lark/.test(u) && /windows/.test(u);
    },
    isIHaierIos() {
        return /lark/.test(u) && /iphone/.test(u);
    },
    isIHaierAndroid() {
        return /lark/.test(u) && /android/.test(u);
    },
    get() {
        if (this.isWeChat()) {
            return 2; // 微信
        } else if (this.isGhAnWeb() || this.isGhIosWeb()) {
            return 1; // 观海
        } else if (this.isHbandroid() || this.isHbiOSWeb() || this.isHbWindows()) {
            return 4;  // 海边
        }else if(this.isIHaier()) {
			return 5;  // iHaier
		} else {
            if (this.isIOS() || this.isAndroid()) {
                return 3; // 其他mobile客户端
            } else {
                return 6; // PC端
            }

        }
    },
    // 数据埋点使用
    buriedPoint() {
        if (this.isWeChat()) {
            return '微信'; // 微信
        } 
        // else if (this.isGhAnWeb() || this.isGhIosWeb()) {
        //     return '海尔人'; // 观海
        // } 
        else if (this.isHbandroid() || this.isHbiOSWeb() || this.isHbWindows()) {
            return '海尔人';  // 海边
        }
        else if(this.isIHaier()) {
			return 'iHaier';  // iHaier
		} else {
			return '其他';  // 其他
        }
    },
    isMobile () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(window.navigator.userAgent) 
        || document.documentElement.clientWidth <= 750
    }
}
export default platform