// 导入vconsole
import Vconsole from 'vconsole'

(window as any).enterTime = new Date().getTime()

const origin = window.location.origin.includes('hilook.com.cn'); // 目前higroup-ihaier.hilook.com.cn为正式环境

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
// 引入vant.js中部分函数形式的组件样式
import './style/vant.ts'
import 'vant/es/dialog/style';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import api from './api'
import DCM from './util/dcm'
import {getQueryString} from './util'
import plateform from '@/util/get-plateform'
import './style/particle.css'
import './style/magazine.css'
import { Lazyload } from 'vant';
import '@/style/index.css'
import '@/components/viewerjs/css/viewer.css'
import '@haier/culture-choose-contacts/dist/style.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// console.log('1.0.6');
// (function () {
//     document.getElementsByTagName('input').onblur = window.scrollTo(0,0)
//     document.getElementsByTagName('textarea').onblur = window.scrollTo(0,0)
//  })()

// dcm导入
// DCM.init()
// 获取token
// DCM.getToken()

if(!origin || getQueryString('debug')) { // 如果为测试环境
    new Vconsole()
}
const app = createApp(App)
app.config.globalProperties.$api = api;
// 打开新页面
app.config.globalProperties['$openNewWindow'] = function(url, type, title) {
    if (plateform.isHbandroid() || plateform.isHbiOSWeb()) {
        const _url = url.indexOf('?') > -1 ? url + '&allowShare=false' : url + '?allowShare=false';
        // DCM.dcmOpenNewContainer({
        //     url: `${window.location.origin}/#/${_url}`
        // })
    }else{
        router.push(url)
    }
}

app.use(Lazyload,{
    loading:require('./assets/h-loading.png'),
}).use(ElementPlus, {
    locale: zhCn,
  }).use(router).use(createPinia()).mount('#app')
