import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  key: 0,
  class: "content"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "right"
};
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = {
  key: 0,
  class: "line"
};
import { ref, watch } from "vue";
import { useRouter, useRoute } from 'vue-router';
import userStore from '@/store';
import DCM from '@/util/dcm';
export default /*@__PURE__*/_defineComponent({
  __name: 'ZoneEntrancePC',
  props: {
    data: Array
  },
  emits: ['handle-area-close', 'handle-area-choose'],
  setup(__props, {
    emit: __emit
  }) {
    const store = userStore();
    const route = useRoute();
    const router = useRouter();
    const props = __props;
    const showAction = ref(false); // 弹框
    const areaList = ref([]); //tab数据
    // 选中id
    const selectedId = ref(0);
    const emit = __emit;
    // 选择数据
    let timer = null;
    const staffToken = sessionStorage.getItem('staff-token');
    const select = item => {
      if (store.loadedId.indexOf(item.id * 1) === -1) {
        store.needRefresh = true;
        store.loadedId.push(item.id * 1);
      } else {
        store.needRefresh = false;
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (staffToken) {
          const url = `${window.location.origin}/mobile/home?id=${item.id}&op_platform_service=hide_navigator&fromihaiermsg=true`;
          DCM.dcmOpenNewContainer({
            url
          });
          return;
        }
        router.push(`/mobile/home?id=${item.id}&op_platform_service=hide_navigator`);
      }, 500);
    };
    function goApply() {
      if (staffToken) {
        const url = `${window.location.origin}/mobile/area_apply?op_platform_service=hide_navigator&fromihaiermsg=true`;
        DCM.dcmOpenNewContainer({
          url
        });
        return;
      }
      router.push(`/mobile/area_apply`);
    }
    watch(() => props.data, val => {
      areaList.value = val;
    }, {
      immediate: true
    });
    const xuehaiFlag = ref(false);
    if (window.sessionStorage.getItem('staff-token')) {
      xuehaiFlag.value = true;
    } else {
      xuehaiFlag.value = false;
    }
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["area-wrap", {
          active: xuehaiFlag.value
        }])
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("span", null, "专区", -1)), !_unref(route).query.tenant_id ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "area-apply",
        onClick: goApply
      }, [_createVNode(_component_van_icon, {
        name: "plus"
      }), _cache[0] || (_cache[0] = _createElementVNode("span", null, "申请专区", -1))])) : _createCommentVNode("", true)]), areaList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(areaList.value, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "area-item pointer",
          key: `area-search-${item.id}`,
          onClick: $event => select(item)
        }, [_createElementVNode("img", {
          src: item.groupIcon,
          class: "area-img"
        }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
          class: _normalizeClass(["name", {
            active: _unref(route).query.id != item.id
          }]),
          innerHTML: item.groupName
        }, null, 10, _hoisted_7)]), _unref(route).query.id == item.id ? (_openBlock(), _createElementBlock("div", _hoisted_8)) : _createCommentVNode("", true)], 8, _hoisted_4);
      }), 128))])) : _createCommentVNode("", true)])], 2);
    };
  }
});