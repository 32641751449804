import api from '../api'
import OSS from 'ali-oss'
import DCM from '@/util/dcm'
import { api as viewerApi } from "@/components/viewerjs/main.js"
import platform from './get-plateform'

// 获取随机命名
export function getRandomName(type: string) {
  const date = new Date()
  const year = date.getFullYear()
  const month = ('00' + (date.getMonth() + 1)).slice(-2)
  const day = ('00' + date.getDate()).slice(-2)
  const randomName = `${year}${month}${day}-${random_string(6)}-${date.getTime()}.${type}`
  return randomName
}
// 随机生成字符串
function random_string(len: number) {
  len = len || 32
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

interface IOssRes {
  AccessKeyId?: number
  AccessKeySecret?: string
  SecurityToken?: string
  region?: string
  bucket?: string
}
// 上传文件方法
export const uploadFileToOss = (file: any) => {
  return new Promise((resolve, reject) => {
    try {
      // 首先获取临时token
      api.getStsAssume().then(function (res: any) {
        console.log('file:', file, res)
        const fileType = file.type.split('/')[1]
        const { AccessKeyId, AccessKeySecret, SecurityToken, region, bucket } = res.data
        const client = new OSS({
          bucket,
          region: region,
          stsToken: SecurityToken,
          accessKeyId: AccessKeyId,
          accessKeySecret: AccessKeySecret
        })
        console.log('client:', client)
        // 封面地址
        // http://a-image-demo.oss-cn-qingdao.aliyuncs.com/demo.mp4?spm=a2c4e.11153940.blogcont573781.6.c1032027sQ46VG&x-oss-process=video/snapshot,t_10000,m_fast
        client
          .multipartUpload(getRandomName(fileType), file)
          .then((r: any) => {
            console.log('re:', r)
            // 将拿到的URL地址resolve
            const url = `http://${bucket}.${region}.aliyuncs.com/${r.name}`
            console.log(url)
            resolve(url)
          })
          .catch((err: any) => {
            reject(err)
          })
      })
    } catch (error) {
      reject(error)
    }
  })
}
// 上传其他文件
export const uploadOtherFileToOss = (file: any) => {
  return new Promise((resolve, reject) => {
    try {
      // 首先获取临时token
      api.getStsAssume().then(function (res: any) {
        console.log('file:', file, res)
        const fileType = file.type.split('/')[1]
        const { AccessKeyId, AccessKeySecret, SecurityToken, region, bucket } = res.data
        const client = new OSS({
          bucket,
          region: region,
          stsToken: SecurityToken,
          accessKeyId: AccessKeyId,
          accessKeySecret: AccessKeySecret
        })
        console.log('client:', client)
        // 封面地址
        // http://a-image-demo.oss-cn-qingdao.aliyuncs.com/demo.mp4?spm=a2c4e.11153940.blogcont573781.6.c1032027sQ46VG&x-oss-process=video/snapshot,t_10000,m_fast
        client
          .multipartUpload(getRandomName(file.name), file)
          .then((r: any) => {
            console.log('re:', r)
            // 将拿到的URL地址resolve
            const url = `https://${bucket}.${region}.aliyuncs.com/${r.name}`
            console.log(url)
            resolve(url)
          })
          .catch((err: any) => {
            reject(err)
          })
      })
    } catch (error) {
      reject(error)
    }
  })
}
// 判断用户是否有权限操作
export const checkAuthority = (groupId: any) => {
  return new Promise((resolve, reject) => {
    try {
      api.checkAuthority(groupId).then(function (res: any) {
        console.log('res:', res)
        if(res) {
          resolve(true)
        } else {
          resolve(false)
        }
      }).catch(() => { 
        resolve(false)
      })
    } catch (error) {
      resolve(false)
    }
  })
}
//js函数代码：字符串转换为时间戳
export function getDateTimeStamp(dateStr) {
  return Date.parse(dateStr.replace(/-/gi, '/'))
}

//JavaScript函数：
export function getDateDiff(dates) {
  // if(!date) return ''
  const date = `${dates.substr(0, 4)}/${dates.substr(5, 2)}/${dates.substr(8, 2)}${dates.substr(10, 9)}`
  const dateTimeStamp = typeof date === 'number' ? date : new Date(date).getTime()
  let result = ''
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  // var halfamonth = day * 15;
  const month = day * 30

  const now = new Date().getTime()
  const diffValue = now - dateTimeStamp
  if (diffValue < 0) {
    //若日期不符则弹出窗口告之
    //alert("结束日期不能小于开始日期！");
  }
  const monthC = diffValue / month
  const weekC = diffValue / (7 * day)
  const dayC = diffValue / day
  const hourC = diffValue / hour
  const minC = diffValue / minute


  const dateStamp = new Date(new Date().toLocaleDateString()).getTime(); // 当天0点的时间戳
  const yesterday = new Date(new Date().toLocaleDateString()).getTime() - 86400000; // 昨天0点的时间戳
  const yearOne = new Date();
  
  yearOne.setFullYear(yearOne.getFullYear() - 1)
  const oneYear = new Date(yearOne.toLocaleDateString()).getTime(); // 获取一年以前的时间戳
  const thisYear = JSON.stringify(new Date().getFullYear()) != dates.substr(0, 4); // 是否为今年
  if (thisYear) {
    result = `${dates.substr(0, 4)}年${dates.substr(5, 2)}月${dates.substr(8, 2)}日`
  } else if (!thisYear && yesterday > dateTimeStamp) {
    result = `${date.substr(5, 2)}月${date.substr(8, 2)}日`
  } else if (yesterday < dateTimeStamp && dateStamp > dateTimeStamp) { // 昨天
    result = `昨天 ${date.substr(11, 2)}:${date.substr(14, 2)}`
  } else if (hourC >= 1 && hourC < 24) { // 当天
    result = `${date.substr(11, 2)}:${date.substr(14, 2)}`
  } else if (minC >= 1) {
    result = Math.floor(minC) + '分钟前'
  } else result = '刚刚'
  return result
}
export function fileIsImage(e) {
  const image = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'];
  // console.log(e, '123');
  
  const index = e.lastIndexOf('.');
  const str = e.substr(index + 1).split('?')[0];
  return image.indexOf(str.toLowerCase()) !== -1;
}

// 判断元素所在屏幕位置：屏幕上-'upper', 屏幕中-'in', 屏幕下-'lower'
export function update(prefix, id, compareLowerValue, compareUuperValue) {
  const elem = document.getElementById(prefix + id);
  const rect = elem.getBoundingClientRect();
  return rect.top<compareLowerValue?'upper':rect.top < compareUuperValue?'in':'lower'
}
// 秒转分秒
export function transSecond(s, h = 0) {
  h = Math.floor(s / 60);
  // 计算秒
  // 取得秒%60的余数，既得到秒数
  s = s % 60;
  if (h != 0) {
      return (h > 9 ? h : '0' + h) + ':' + (s > 9 ? s : '0'+ s);
  } else {
      return '00:' + (s > 9 ? s : '0'+ s);
  }
}


// // 获取url参数
export const getQueryString = (name) => {
	const path = window.location.href;
	const reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
	if (reg.test(path)) {
		return unescape(RegExp.$2.replace(/\+/g, " "));
	}else{
		return "";
	}
}

/**
 * 
 * @param type 实名/昵称
 * @param jobnumber 工号staffid
 * @param isMe 是否为本人
 */
// 点击进入个人空间
// 查看他人空间，仅仅实名情况
// 查看自己空间，实名/匿名均可
export function clickToPersonalSpace(type, jobnumber, isMe){
  console.log(111,type, jobnumber)
  const origin = window.location.origin.includes('hilook.com.cn')
  let _url =  `&visit=${type}&jobnumber=${jobnumber}`

  if(origin) {
    _url = 'https://xue.hilook.com.cn/#/pages/personalSpace/personalSpace?from=group' + _url
    _url = encodeURIComponent(_url)
    _url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c7b57abb62d00e&lk_target_url=${_url}`
  }else{
    _url = 'https://haixueh5-test.lanbenzi.cn:8443/#/pages/personalSpace/personalSpace?from=group' + _url
    _url = encodeURIComponent(_url)
    _url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c4870b3039500e&lk_target_url=${_url}`
  }
  console.log('line 269 _url:',_url); 
  window.open(_url)
}
// 获取系统时间
export function getSystemTime() {
  const date = new Date(),
      year = date.getFullYear(),
      month = toTwo(date.getMonth() + 1),
      day = toTwo(date.getDate()),
      hour = toTwo(date.getHours()),
      minite = toTwo(date.getMinutes()),
      second = toTwo(date.getSeconds())
  return `${year}-${month}-${day} ${hour}:${minite}:${second}`
}
function toTwo (num) {
  return num > 9 ? num : '0' + num
}



/**
 * @param dates 时间字符串
 * 当天显示 'HH: mm'
 * 昨天和前天显示“昨天 HH: mm”“前天HH: mm”
 * 前天之前且当年的显示“MM-DD HH: mm”
 * 非当年的显示“YYYY-MM-DD HH: mm”
 */
export function getDateDiff2(dates) {
  // 这是个数字number
  const currentYear = new Date().getFullYear()

  if(dates.substr(0, 4) != currentYear) {
    // 非当年的显示“YYYY-MM-DD HH: mm”
    return dates.substr(0, 16)
  }else{
    const date = `${dates.substr(0, 4)}/${dates.substr(5, 2)}/${dates.substr(8, 2)}${dates.substr(10, 9)}`

    const dateStamp = new Date(new Date().toLocaleDateString()).getTime(); // 当天0点的时间戳
    const yesterday = new Date(new Date().toLocaleDateString()).getTime() - 86400000; // 昨天0点的时间戳
    const daybeforeYesterday = new Date(new Date().toLocaleDateString()).getTime() - 86400000*2; // 昨天0点的时间戳

    const dateTimeStamp = new Date(date).getTime()
    let result = ''
    if(dateTimeStamp >= dateStamp) {
      result = dates.substr(11,5)
    } else if(yesterday <= dateTimeStamp){
      result = '昨天'+ dates.substr(11,5)
    } else if(daybeforeYesterday <= dateTimeStamp){
      result = '前天'+ dates.substr(11,5)
    } else {
      result = dates.substr(5, 11)
    }

    return result
  }
}
// 获取ios键盘高度
// 获取系统时间
export function getJPHeight(type) {
  return 400
  
}

// 跳转至学海页面
export const goXuehaiPage = (_url) => {
  const origin = window.location.origin.includes('hilook.com.cn')
  let url = ''
  if (origin) {
      url = `https://xue.hilook.com.cn/#${_url}`
      url = encodeURIComponent(url)
      url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c7b57abb62d00e&lk_target_url=${url}`
  } else {
      url = `https://haixueh5-test.lanbenzi.cn:8443/#${_url}`
      url = encodeURIComponent(url)
      url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c4870b3039500e&lk_target_url=${url}`
  }
  DCM.dcmOpenNewContainer({ url })
}
// 跳转至学海页面==隐藏自带头展示系统头
export const goXuehaiPageHeader = (_url) => {
  const origin = window.location.origin.includes('hilook.com.cn')
  let url = ''
  if (origin) {
      url = `https://xue.hilook.com.cn/#${_url}`
      url = encodeURIComponent(url)
      url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c7b57abb62d00e&lk_target_url=${url}`
  } else {
      url = `https://haixueh5-test.lanbenzi.cn:8443/#${_url}`
      url = encodeURIComponent(url)
      url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c4870b3039500e&lk_target_url=${url}`
  }
  DCM.dcmOpenNewContainer({ url })
}
export function replaceSpace(val:string) {
  let name = ''
  name = val.replace(new RegExp(`\n`,"gm"),``)
  return name
}
// 跳转融媒云 
 export function goRongYun(params) {
  const flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(window.navigator.userAgent)
  if (!flag)  {
    params.platform = 'PC'
  } else {
    params.platform = 'H5'
  }
  const origin = window.location.origin.includes('hilook.com.cn')
  const backUrl =  encodeURIComponent(params.callBackUrl)
  let callbackUrl = ''
  if (origin) {
    callbackUrl = `https://applink.feishu.cn/client/web_app/open?appId=cli_a31d5ed5097b100b&lk_target_url=${backUrl}&reload=true`
  } else {
    callbackUrl = `https://applink.feishu.cn/client/web_app/open?appId=cli_a31d4ad225fad013&lk_target_url=${backUrl}&reload=true`
  }
  api.getFontUrl(params).then(({data}) => {
    let url = data
    let finalUrl = ''
    const tenant_id = localStorage.getItem('tenant_id') || null
    url=url+`&tenantId=${tenant_id}`
    if (params.type == 2) {
      if (params.platform == 'PC') {
        if (params.typeId == 1) { // 餐厅入口
          finalUrl = `${url}&typeId=${params.typeId}&source=group&callbackUrl=${encodeURIComponent(callbackUrl)}`
        } else {
          if (params.groupId && params.topicId) {
            finalUrl = `${url}&groupId=${params.groupId}&topicId=${params.topicId}&source=group&selectFlag=true&callbackUrl=${encodeURIComponent(callbackUrl)}`
          } else if (params.groupId && !params.topicId) {
            finalUrl = `${url}&groupId=${params.groupId}&source=group&selectFlag=true&callbackUrl=${encodeURIComponent(callbackUrl)}`
          } else if (!params.groupId && !params.topicId){
            finalUrl = `${url}&source=group&callbackUrl=${encodeURIComponent(callbackUrl)}`
          }
        }
        DCM.dcmOpenNewContainer({ url:finalUrl })
      } else { 
        if (params.typeId == 1) { // 餐厅入口
          finalUrl = `${url}&typeId=${params.typeId}&source=group`
        } else {
          if (params.groupId && params.topicId) {
            finalUrl = `${url}&groupId=${params.groupId}&topicId=${params.topicId}&source=group&selectFlag=true`
          } else if (params.groupId && !params.topicId) {
            finalUrl = `${url}&groupId=${params.groupId}&source=group&selectFlag=true`
          } else if (!params.groupId && !params.topicId){
            finalUrl = `${url}&source=group`
          }
        }
        DCM.dcmOpenNewContainer({ url:finalUrl })
      }
    } else {
      finalUrl = `${url}&source=group`
      DCM.dcmOpenNewContainer({ url:finalUrl })
    }
  })
}
// 跳转融媒云 备份
 export function goRongYun1111(params) {
  const flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(window.navigator.userAgent)
  if (!flag)  {
    params.platform = 'PC'
  } else {
    params.platform = 'H5'
  }
  params.type = 2
  const origin = window.location.origin.includes('hilook.com.cn')
  const backUrl =  encodeURIComponent(params.callBackUrl)
  let callbackUrl = ''
  if (origin) {
    callbackUrl = `https://applink.feishu.cn/client/web_app/open?appId=cli_a31d5ed5097b100b&lk_target_url=${backUrl}`
  } else {
    callbackUrl = `https://applink.feishu.cn/client/web_app/open?appId=cli_a31d4ad225fad013&lk_target_url=${backUrl}`
  }
  api.getFontUrl(params).then(({data}) => {
    let url = data
    let finalUrl = ''
    // 手机端1：最优 大讨论（保留） ==》（不带callbackUrl） ==》融媒云发帖 ==》发帖成功关闭融媒云 ==》显示上个页面既大讨论 ==》 数据自动刷新（maybe）
    // 问题1：不发帖直接关闭融媒云时，页面会刷新
    // 手机端2：大讨论（关闭） ==》（带callbackUrl）==》 融媒云发帖 ==》发帖成功关闭融媒云 ==》打开大讨论 ==》 数据自动刷新
    // 问题1：使用系统返回键的时候，返回不了大讨论（已经被关闭）

    // PC端1：大讨论（保留） ==》（不带callbackUrl）==》 融媒云发帖 ==》发帖成功关闭融媒云 ==》显示上个页面既大讨论 ==》 数据自动刷新 （has test）
    // 问题1：不发帖直接关闭融媒云时，页面会刷新
    // 问题2：上个页面不一定是大讨论
    // PC端2 最优：大讨论（关闭） ==》（带callbackUrl）==》 融媒云发帖 ==》发帖成功关闭融媒云 ==》打开大讨论 ==》 数据自动刷新 
    if (params.platform == 'PC') {
      // PC端2 最优：大讨论（关闭） ==》（带callbackUrl）==》 融媒云发帖 ==》发帖成功关闭融媒云 ==》打开大讨论 ==》 数据自动刷新 
      finalUrl = encodeURIComponent(`${url}&groupId=${params.groupId}&topicId=${params.topicId}&source=group&callbackUrl=${encodeURIComponent(callbackUrl)}`)
      if (origin) {
        url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c7b57abb62d00e&lk_target_url=${finalUrl}`
      } else {
        url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c4870b3039500e&lk_target_url=${finalUrl}`
      }
      DCM.dcmOpenNewContainer({ url })
      setTimeout(() => {
        DCM.dcmCloseCurrentContainer()
      },300)
    } else {
      // 手机端：大讨论（保留） ==》（不带callbackUrl） ==》融媒云发帖 ==》发帖成功关闭融媒云 ==》显示上个页面既大讨论 ==》 数据自动刷新（ma
      // 问题1：不发帖直接关闭融媒云时，页面会刷新
      finalUrl = encodeURIComponent(`${url}&groupId=${params.groupId}&topicId=${params.topicId}&source=group`)
      if (origin) {
        url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c7b57abb62d00e&lk_target_url=${finalUrl}`
      } else {
        url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c4870b3039500e&lk_target_url=${finalUrl}`
      }
      DCM.dcmOpenNewContainer({ url })
    }
  })
}

//viewer 预览图片
/**
 * 
 * @param {*} currentIndex --当前定位的图片
 * @param {*} imgList --图片列表
 */
export const viewerImage = (currentIndex, imgList, toHistoryIndex=false)=>{
	let current = currentIndex || 0
	let canShare = true
	// 处理移动端样式
	if(platform.isIHaierMobile()) {
		// if(current >= 0){
		viewerApi({
        options: {
            toolbar: false,
            initialViewIndex: current,
            navbar: true,
            title: false,
			      maxZoomRatio: 10,
            rotatable: true,
            scalable: false,
            zoomable: true,
            loop: true,
            fullscreen: false,
            view: function (event) {
              console.log(123, event.detail.index)
              current = event.detail.index
              document.querySelector('.viewer-pagination').innerHTML = 
                (current+1) + '/'+ document.querySelector('.viewer-list').childNodes.length
            },
            show: function () {
              // 导航图片隐藏
              (document.querySelector('.viewer-navbar') as HTMLElement).style.visibility = 'hidden'
              // 关闭按钮左移
              document.querySelector('.viewer-button.viewer-close').className = 'viewer-button viewer-close mobile'
              // 分享按钮添加
              const oDiv = document.querySelector('.viewer-container')
              const share = document.createElement("div");
              share.className = 'viewer-share'
              share.onclick=function(){
                if(!canShare) return
                canShare = false
                DCM.sendCardOnlyImg({imgUrl:imgList[current]})
                .then(()=>{
                  canShare = true
                }, ()=>{
                  canShare = true
                })
              }
              oDiv.append(share)

              // 手机端的旋转按钮
              const viewerInstance = this.viewer
              const rotate = document.createElement("div");
              rotate.className = 'viewer-rotate'
              rotate.onclick=function(){
                viewerInstance.rotate(-90)
              }
              oDiv.append(rotate)

              // 当前分页
              const pagination = document.createElement("div");
              const imgNum = document.querySelector('.viewer-list').childNodes.length
              pagination.className = 'viewer-pagination'
              pagination.innerHTML = current + '/'+imgNum
              oDiv.append(pagination)

              // history 入口添加
              const entrance = document.createElement("div");
              entrance.className = 'history-entrance mobile'
              entrance.append('HISTORY')
              entrance.onclick=function(){
                // const url = linkTo || (location.href.includes('hilook.com.cn')?platform.isIHaierMobile()?'https://history.hilook.com.cn?op_platform_service=hide_navigator':'https://history.hilook.com.cn':'https://history-dev.lanbenzi.cn')
                let url = ''
                const dynInfo = sessionStorage.getItem('dynInfo')?JSON.parse(sessionStorage.getItem('dynInfo')):{}
                const origin = location.href.includes('hilook.com.cn')?'https://history.hilook.com.cn':'https://history-dev.lanbenzi.cn'
                const mediaId = imgList[current].mediaId
                if(toHistoryIndex) {
                  // 匿名帖子直接跳转至history首页
                  url = origin + `?from=dataolun&from_info_id=${dynInfo.did}&from_info_title=${dynInfo.dcontent}}`
                  if(platform.isIHaierMobile()){
                    url +='&op_platform_service=hide_navigator'
                  }
                } else if(mediaId) {
                  // 新版本数据都有historyId
                  url = origin+`/web/detail?mediaId=${mediaId}&from=dataolun&from_info_id=${dynInfo.did}&from_info_title=${dynInfo.dcontent}`
                  if(platform.isIHaierMobile()){
                    url +='&op_platform_service=hide_navigator'
                  }
                }else{
                  // 存量数据没有
                  url = origin + `?from=dataolun&from_info_id=${dynInfo.did}&from_info_title=${dynInfo.dcontent}}`
                  if(platform.isIHaierMobile()){
                    url +='&op_platform_service=hide_navigator'
                  }
                }
                DCM.dcmOpenNewContainer({url: url})
              }
              oDiv.append(entrance)
            }
        },
        images: imgList
    })
	} else {
		const showNav = imgList.length > 1
	  viewerApi({
        options: {
            toolbar: {
              rotateLeft: 1,// 向左旋转图片
              zoomIn: 1, // 放大图片
            //   reset: 1,
			        oneToOne: 0, // 图片比例100%
              zoomOut: 1, //缩小图片
              // reset: 0, // 重置图片大小
              prev: 1, // 查看上一张图片
              // play: 0, // 播放图片
              next: 1,// 查看下一张图片
              rotateRight: function () {
                console.log('canshare:', canShare)
                if(!canShare) return
                canShare = false
                        DCM.sendCardOnlyImg({imgUrl:imgList[current]})
                  .then(()=>{
                    canShare = true
                  }, ()=>{
                    canShare = true
                  })
              }
            },
			
            initialViewIndex: current,
            navbar: showNav,
            title: false,
            maxZoomRatio: 10,
            minZoomRatio: 0.1,
            rotatable: true,
            scalable: true,
            fullscreen: false,
            loop: false,
            loading: false,
            view: function (event) {
              current = event.detail.index
            },
            show: function () {
              // console.log('view show')
              const oDiv = document.querySelector('.viewer-container')
              // console.log('oDiv：', oDiv)
              const entrance = document.createElement("div");
              entrance.className = 'history-entrance pc'
              entrance.append('HISTORY')
              entrance.onclick=function(){
                // const url = linkTo || (location.href.includes('hilook.com.cn')?platform.isIHaierMobile()?'https://history.hilook.com.cn?op_platform_service=hide_navigator':'https://history.hilook.com.cn':'https://history-dev.lanbenzi.cn')
                let url = ''
                const dynInfo = sessionStorage.getItem('dynInfo')?JSON.parse(sessionStorage.getItem('dynInfo')):{}
                const origin = location.href.includes('hilook.com.cn')?'https://history.hilook.com.cn':'https://history-dev.lanbenzi.cn'
                const mediaId = imgList[current].mediaId
                if(toHistoryIndex) {
                  // 匿名帖子直接跳转至history首页
                  url = origin + `?from=dataolun&from_info_id=${dynInfo.did}&from_info_title=${dynInfo.dcontent}}`
                  if(platform.isIHaierMobile()){
                    url +='&op_platform_service=hide_navigator'
                  }
                }else if(mediaId) {
                  // 新版本数据都有historyId
                  url = origin+`/web/detail?mediaId=${mediaId}&from=dataolun&from_info_id=${dynInfo.did}&from_info_title=${dynInfo.dcontent}`
                  if(platform.isIHaierMobile()){
                    url +='&op_platform_service=hide_navigator'
                  }
                }else{
                  // 存量数据没有
                  url = origin + `?from=dataolun&from_info_id=${dynInfo.did}&from_info_title=${dynInfo.dcontent}}`
                  if(platform.isIHaierMobile()){
                    url +='&op_platform_service=hide_navigator'
                  }
                }
                DCM.dcmOpenNewContainer({url: url})
              }
              oDiv.append(entrance)
              // console.log('this.viewer 111:', this.viewer)
              const viewerInstance = this.viewer
              /*
              if(imgList.length > 10) {
                const left = document.createElement('div')
                left.className = 'next_btn2 btn_left'
                left.onclick = function () {
                  viewerInstance.prev(false)
                }
                const right = document.createElement('div')
                right.className = 'next_btn2 btn_right'
                right.onclick = function () {
                  viewerInstance.next(false)
                }
                const p = document.querySelector('.viewer-navbar')
                p.append(left)
                p.append(right)
              }*/
              if(imgList.length === 1) {
                if(document.querySelector('.viewer-prev')){
                  (document.querySelector('.viewer-prev') as HTMLElement).style.display = 'none'
                }
                if(document.querySelector('.viewer-next')){
                  (document.querySelector('.viewer-next') as HTMLElement).style.display = 'none'
                }
              }
            }
      },
        images: imgList
    })
	}
}
export const ossUploadBolb = (blob) => {
  return new Promise((resolve, reject) => {
    try {
      // 首先获取临时token
      api.getStsAssume().then(async function (res: any) {
        const { AccessKeyId, AccessKeySecret, SecurityToken, region, bucket } = res.data
        const client = new OSS({
          bucket,
          region: region,
          stsToken: SecurityToken,
          accessKeyId: AccessKeyId,
          accessKeySecret: AccessKeySecret
        })
        console.log('client:', client)
        const time = new Date().getTime();
        // 使用split()方法将文件名拆分成数组

        const result = await client.put('higroup-' + time + (Math.random() * 1000).toFixed(0) + '.png', blob);
        console.log(result);
        resolve(result);
      })
    } catch (error) {
      reject(error)
    }
  })
}