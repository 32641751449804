import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DCM from '../util/dcm'
import api from '../api'
import Layout from "@/components/Layout/HomeLayout.vue"
import userStore from '@/store'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: "/mobile/home?id=36",
  },
  {
    path: '/',
    component: Layout,
    children:[
      // {
      //   path: '/mobile/group/share/:id',
      //   name: 'GroupShare',
      //   component: () => import('../views/mobile/GroupShare.vue'),
      //   meta: {
      //     title: '小组二维码',
      //     notLogin: true
      //   }
      // },
      {
        path: '/mobile/news/create',
        name: 'NewsCreate',
        component: () => import('../views/mobile/NewsCreate.vue'),
        meta: {
          title: '发贴',
          notLogin: false
        }
      },
      {
        path: '/mobile',
        name: 'Mobile',
        children: [
          {
            path: 'home',
            name: 'GroupHome',
            component: () => import('../views/mobile/GroupHome.vue'),
            meta: {
              title: '动态列表',
              notLogin: false,
              keepAlive: true,
              menu: true,
            },
          },
          {
            path: 'news/detail',
            name: 'NewsDetail',
            component: () => import('../views/mobile/NewsDetail.vue'),
            meta: {
              title: '动态详情',
              notLogin: false
            }
          }
        ]
      },{
        path: '/mobile/group/join/:id',
        name: 'GroupJoin',
        component: () => import('../views/mobile/GroupJoin.vue'),
        meta: {
          title: '加入小组',
          notLogin: false
        }
      },
      {
        path: '/mobile/group/setting',
        name: 'GroupSetting',
        component: () => import('../views/mobile/GroupSetting.vue'),
        meta: {
          title: '小组设置',
          notLogin: false,
          keepAlive: true
        }
      },
      {
        path: '/mobile/group/config/admin',
        name: 'ConfigAdmin',
        component: () => import('../views/mobile/ConfigAdmin.vue'),
        meta: {
          title: '配置管理员',
          notLogin: false
        }
      },
      {
        path: '/mobile/group/standard',
        name: 'Standard',
        component: () => import('../views/mobile/ManagementSpecifications.vue'),
        meta: {
          title: '小组管理规范',
          // notLogin: true,
          keepAlive: false
        }
      },
      {
        path: '/mobile/group/message',
        name: 'Message',
        component: () => import('../views/mobile/MessageCenter.vue'),
        meta: {
          title: '消息',
          notLogin: true
        }
      },
      {
        path: '/mobile/error/:type',
        name: 'ErrorPage',
        component: () => import('../views/mobile/ErrorPage.vue'),
        meta: {
          title: '',
          notLogin: true
        }
      },
      {
        path: '/mobile/no/permission',
        name: 'NoPermission',
        component: () => import('../views/mobile/NoPermission.vue'),
        meta: {
          title: '',
          notLogin: true
        }
      },
      // {
      //   path: '/mobile/default/page',
      //   name: 'DefaultPage',
      //   component: () => import('../views/mobile/DefaultPage.vue'),
      //   meta: {
      //     title: ''
      //   }
      // },
      // {
      //   path: '/mobile/userCard',
      //   name: 'UserCard',
      //   component: () => import('../views/mobile/userCard.vue'),
      //   meta: {
      //     title: ''
      //   }
      // },
      {
        path: '/mobile/canteenList',
        name: 'UserCard',
        component: () => import('../views/mobile/canteenList.vue'),
        meta: {
          title: ''
        }
      },
      // {
      //   path: '/mobile/rank',
      //   name: 'HotRank',
      //   component: () => import('../views/mobile/HotRank.vue'),
      //   meta: {
      //     title: '排行榜'
      //   }
      // },
      {
        path: '/mobile/topic',
        name: 'TopicDetail',
        component: () => import('../views/mobile/TopicDetail.vue'),
        meta: {
          title: '',
          // keepAlive: true
        }
      },
      {
        path: '/mobile/label',
        name: 'LabelDetail',
        component: () => import('../views/mobile/LabelDetail.vue'),
        meta: {
          title: '',
          keepAlive: true
        }
      },
      // {
      //   path: '/mobile/topic_progress',
      //   name: 'TopicProgress',
      //   component: () => import('../views/mobile/TopicProgress.vue'),
      //   meta: {
      //     title: ''
      //   }
      // },
      {
        path: '/mobile/group/change_topic_type',
        name: 'ChangeTopicType',
        component: () => import('../views/mobile/TopicChangeType.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: '/mobile/group/search/:id',
        name: 'GroupSearch',
        component: () => import('../views/mobile/NewsSearch.vue'),
        meta: {
          title: '',
          keepAlive: true
        }
      },
      {
        path: '/mobile/problem_solving',
        name: 'problemSolving',
        component: () => import('../views/mobile/ProblemSolving.vue'),
        meta: {
          title: '',
          keepAlive: true
        }
      },
      // {
      //   path: '/mobile/canteen',
      //   name: 'canteenList',
      //   component: () => import('../views/mobile/canteenList.vue'),
      //   meta: {
      //     title: '',
      //   }
      // },
      {
        path: '/mobile/feishu_api',
        name: 'FeishuApi',
        component: () => import('../views/mobile/FeishuApi.vue'),
        meta: {
          title: '',
        }
      },
      {
        path: '/mobile/canteen_square',
        name: 'canteenSquare',
        component: () => import('../views/mobile/canteenSquare.vue'),
        meta: {
          title: '',
          keepAlive: true,
        }
      },
      {
        path: '/mobile/notice/setting',
        name: 'NoticeSetting',
        component: () => import('../views/mobile/NoticeSetting.vue'),
        meta: {
          title: '',
          keepAlive: true,
        }
      },
      {
        path: '/mobile/newLabel',
        name: 'NewLabel',
        component: () => import('../views/mobile/NewLabel.vue'),
        meta: {
          title: '新建标签',
        }
      },
      {
        path: '/mobile/change_label',
        name: 'ChangeLabel',
        component: () => import('../views/mobile/LabelChange.vue'),
        meta: {
          title: ''
        }
      },
      {
        
        path: '/mobile/group/helper',
        name: 'GroupHelper',
        component: () => import('../views/mobile/SettingHelper.vue'),
        meta: {
          title: ''
        }
      },
      {
        
        path: '/mobile/group/board',
        name: 'HaierRenBoard',
        component: () => import('../views/mobile/HaierRenBoard.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: '/mobile/sendMessage',
        name: 'SendMessage',
        component: () => import('../views/mobile/sendMessage.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: '/mobile/group/messagelist',
        name: 'MessageList',
        component: () => import('../views/mobile/MessageCenterList.vue'),
        meta: {
          title: '消息',
          notLogin: true
        }
      },
      {
        path: '/mobile/handbook',
        name: 'HandBook',
        component: () => import('../views/mobile/HandBook.vue'),
        meta: {
          title: '手册',
        }
      },
      // {
      //   path: '/mobile/handbook-category',
      //   name: 'HandBookCategory',
      //   component: () => import('../views/mobile/HandbookCategory.vue'),
      //   meta: {
      //     title: '文化手册',
      //   }
      // },
      {
        path: '/mobile/claimant_list',
        name: 'ClaimantList',
        component: () => import('../views/mobile/ClaimantList.vue'),
        meta: {
          title: '认领人',
          keepAlive: true
        }
      },
      {
        path: '/mobile/hot_news_list',
        name: 'HotNewsList',
        component: () => import('../views/mobile/HotNewsList.vue'),
        meta: {
          title: '热帖',
          keepAlive: true
        }
      },
      {
        path: '/mobile/personal_problem',
        name: 'PersonalProblem',
        component: () => import('../views/mobile/PersonalProblem.vue'),
        meta: {
          title: '个人问题',
          keepAlive: true
        }
      },
      {
        path: '/mobile/area_apply',
        name: 'AreaApply',
        component: () => import('../views/mobile/GroupApply.vue'),
        meta: {
          title: '手册',
        }
      },
      {
        path: '/mobile/no_area',
        name: 'NoArea',
        component: () => import('../views/mobile/NoArea.vue'),
        meta: {
          title: '',
        }
      },
      {
        path: '/mobile/assistant_list',
        name: 'assistantList',
        component: () => import('../views/mobile/AssistantList.vue'),
        meta: {
          title: '',
          keepAlive: true
        }
      },
      {
        path: '/mobile/assistant_analyse',
        name: 'assistantAnalyse',
        component: () => import('../views/mobile/AssistantAnalyse.vue'),
        meta: {
          title: '',
          keepAlive: true
        }
      },
      {
        path: '/mobile/dynamic_list',
        name: 'DynamicList',
        component: () => import('../views/mobile/DynamicList.vue'),
        meta: {
          title: '消息',
          notLogin: true,
          keepAlive: false
        }
      },
      {
        path: '/mobile/wait_claim',
        name: 'WaitClaim',
        component: () => import('../views/mobile/WaitClaim.vue'),
        meta: {
          title: '待认领列表',
          keepAlive: true
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
const store = userStore();
let homeId:any = ''
const getAreaList = async () => {
  return new Promise((resolve,reject) => {
    api.first_sort_id({}).then((res) => {
      if (store.loadedId.indexOf(res.data) === -1 && res.data != 0) {
        store.loadedId.push(res.data*1)
      }
      resolve(res.data)
    })
  })
}
router.beforeEach((to, from, next)=>{
  // (window as any)._axiosPromiseArr.forEach((ele,index) => {
  //   ele.cancel() // 路由跳转之前，清空（终止）上一个页面正在请求的内容
  //   delete (window as any)._axiosPromiseArr[index]
  // })
  console.log('to:', to)
  console.log('from:', from)
  document.title = '全员文化大讨论'
  let tenant_id = window.localStorage.getItem('tenant_id') || ''
  // 原来的地址重定向
  if(to.path == '/mobile/home/36'){
    const id = to.path.split('/mobile/home/')[1]
    const query = window.location.href.split('?')[1]
    const url = window.location.origin+`/mobile/home?id=${id}&${query}`
    window.location.replace(url)
  }
  if(to.query.xueId || window.sessionStorage.getItem('staff-token')) {
    // if (!window.sessionStorage.getItem('staff-token')) {
      window.sessionStorage.setItem('staff-token',to.query.xueId.toString())
    // }
    api.getUserInfo().then((res) => {
      const {data} = res
      const store = userStore();
      store.userInfo = data
      localStorage.setItem('userInfo', JSON.stringify(data))
      if(document.getElementById('pageload')){
        document.getElementById('pageload').style.display = 'none'
      }
      next()
    })
    return
  } 
  // 重定向以前的话题链接
  if(to.path.includes('mobile/topic/')){
    const id = to.path.split('topic/')[1]
    const query = window.location.href.split('?')[1]
    const url = window.location.origin+`/mobile/topic?id=${id}&${query}`
    window.location.replace(url)
  }
 /**
   * 所有路由均带上tenant_id,用以区分是否是租户
   * **/
  if (from.query.tenant_id) {
    if (tenant_id != from.query.tenant_id){
      window.localStorage.setItem('tenant_id',from.query.tenant_id.toString())
    }
  } else {
    tenant_id = ''
  }
  if (to.query.tenant_id) {
    if (tenant_id != to.query.tenant_id) {
      window.localStorage.setItem('tenant_id',to.query.tenant_id.toString())
    }
    tenant_id = to.query.tenant_id.toString()
  } else {
    if (!from.query.tenant_id) {
      window.localStorage.removeItem('tenant_id')
    }
  }
  /**
   * 所有路由均带上tenant_id,用以区分是否是租户
   * **/
  const iHaierToken = localStorage.getItem('iHaierToken');
  if(iHaierToken) {
    api.checkToken({token: iHaierToken}).then(async res => {     
      //鉴权
      DCM.checkAuth(to.fullPath)     
      /**
       * 接口调用判断哪个是首页
       * **/
      if(to.path == '/mobile/home' && to.query.id == '36'){
        // 获取首页信息
        homeId = await getAreaList()
        if (to.query.tenant_id && homeId ) {
          const query = Object.assign({},to.query, {id: homeId})
          next({
            path: to.path,
            query: query
          })
        } else {
          if (homeId) {
            next()
          } else {
            next(`/mobile/no_area?tenant_id=${tenant_id}`)
          }
        }
      } 
      /**
       * 接口调用判断哪个是首页
       * **/
      if(document.getElementById('pageload')){
        document.getElementById('pageload').style.display = 'none'
      }
      if (to.query.tenant_id) {
        if (!to.query.op_platform_service) {
          const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator'})
          next({
            path: to.path,
            query: query
          })
        } else {
          next()
        }
      } else {
        if (tenant_id) {
          if (!to.query.op_platform_service) {
            const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator',tenant_id})
            next({
              path: to.path,
              query: query
            })
          } else {
            const query = Object.assign({},to.query, {tenant_id})
            next({
              path: to.path,
              query: query
            })
          }
        } else {
          if (!to.query.op_platform_service) {
            const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator'})
            next({
              path: to.path,
              query: query
            })
          } else {
            next()
          }
        }
      }
    }).catch(() => {
      // 清除iHaierToken等存储
      const tenantId = localStorage.getItem('tenant_id')
      localStorage.clear()
      if (tenantId) window.localStorage.setItem('tenant_id',tenantId)
      DCM.getToken(to.fullPath).then(async res => {
        /**
         * 接口调用判断哪个是首页
         * **/
        if(to.path == '/mobile/home' && to.query.id == '36'){
          // 获取首页信息
          homeId = await getAreaList()
          if (to.query.tenant_id && homeId ) {
            const query = Object.assign({},to.query, {id: homeId})
            next({
              path: to.path,
              query: query
            })
          } else {
            if (homeId) {
              next()
            } else {
              next(`/mobile/no_area?tenant_id=${tenant_id}`)
            }
          }
        }
        /**
         * 接口调用判断哪个是首页
         * **/
        // loading隐藏
        if(document.getElementById('pageload')){
          document.getElementById('pageload').style.display = 'none'
        }
        if (to.query.tenant_id) {
          if (!to.query.op_platform_service) {
            const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator'})
            next({
              path: to.path,
              query: query
            })
          } else {
            next()
          }
        } else {
          if (tenant_id) {
            if (!to.query.op_platform_service) {
              const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator',tenant_id})
              next({
                path: to.path,
                query: query
              })
            } else {
              const query = Object.assign({},to.query, {tenant_id})
              next({
                path: to.path,
                query: query
              })
            }
          } else {
            if (!to.query.op_platform_service) {
              const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator'})
              next({
                path: to.path,
                query: query
              })
            } else {
              next()
            }
          }
        }
      })
    })
  }else{
    try {
      DCM.getToken(to.fullPath).then(async res => {
        /**
         * 接口调用判断哪个是首页
         * **/
        if(to.path == '/mobile/home' && to.query.id == '36'){
          // 获取首页信息
          homeId = await getAreaList()
          if (to.query.tenant_id && homeId ) {
            const query = Object.assign({},to.query, {id: homeId})
            next({
              path: to.path,
              query: query
            })
          } else {
            if (homeId) {
              next()
            } else {
              next(`/mobile/no_area?tenant_id=${tenant_id}`)
            }
          }
        }
        /**
         * 接口调用判断哪个是首页
         * **/
        if(document.getElementById('pageload')){
          document.getElementById('pageload').style.display = 'none'
        }
        if (to.query.tenant_id) {
          if (!to.query.op_platform_service) {
            const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator'})
            next({
              path: to.path,
              query: query
            })
          } else {
            next()
          }
        } else {
          if (tenant_id) {
            if (!to.query.op_platform_service) {
              const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator',tenant_id})
              next({
                path: to.path,
                query: query
              })
            } else {
              const query = Object.assign({},to.query, {tenant_id})
              next({
                path: to.path,
                query: query
              })
            }
          } else {
            if (!to.query.op_platform_service) {
              const query = Object.assign({},to.query, {op_platform_service: 'hide_navigator'})
              next({
                path: to.path,
                query: query
              })
            } else {
              next()
            }
          }
        }
      })
    } catch (error) {
      console.log('路由', error);
    }
  }
})
export default router
