import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import api from "@/api";
import ZoneEntrancePc from '@/components/ZoneEntrancePC.vue';
import userStore from '@/store';
export default /*@__PURE__*/_defineComponent({
  __name: 'HomeLayout',
  setup(__props) {
    const store = userStore();
    const areaList = ref([]);
    const getAreaList = async () => {
      const data = await api.getAreaList();
      let other = data.data && data.data.filter(item => item.typeId == 2);
      areaList.value = other;
    };
    getAreaList();
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.fullPath
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.fullPath
        })) : _createCommentVNode("", true)]),
        _: 1
      }), !_unref(store).isScreenMobile && _ctx.$route.meta.menu ? (_openBlock(), _createBlock(ZoneEntrancePc, {
        key: 0,
        data: areaList.value
      }, null, 8, ["data"])) : _createCommentVNode("", true)]);
    };
  }
});