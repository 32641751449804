import request from './request'
// console.log(process.env, 'process.env');
// const settingUrl = process.env.NODE_ENV === 'development' ? '' : 'http://haibianserver-test.lanbenzi.cn/api';
const settingUrl = process.env.NODE_ENV === 'development' ? '' : '';
const getManagerDetail = (data: any) => {
  return request.post('/api/admin/manager_detail', data)
}
// 搜索组件-多条件模糊查询及分页（姓名和工号）
const getQueryFuzzy = (params: any) => {
    return request.get(settingUrl + '/dcm/search/v1/selectContacts', params)
}
// 点击我的组织-获取当前企业下所有根节点
const contactRootNote = () => {
  return request.get(settingUrl + "/dcm/organizationNodeInfo/v1/contactRootNote");
}

// 通过组织节点id查询其下子节点信息和员工信息
const contactNote = (oid: any) => {
  return request.get(settingUrl + `/dcm/organizationNodeInfo/v1/contactNote/${oid}`);
}

// 选人组件
const selectedUsers = (data: any) => {
  return request.post(settingUrl + "/dcm/components/v1/selectedUsers",data)
}

// 选人查询名称
const selectNames = (data: any) => {
  return request.post(settingUrl + "/dcm/components/v1/selectNames",data)
}

// 后台-小组列表
const groupList = (data: any) => {
  return request.post("/api/group/back/list",data)
}
// 后台-小组列表
const addGroup = (data: any) => {
  return request.post("/api/group/back/add",data)
}
// 后台-获取小组详情
const groupBackDetail = (data: any) => {
  return request.get(`/api/group/back/detail?id=${data}`,data)
}
// 后台-启用禁用小组
const groupStatus = (data: any) => {
  return request.post("/api/group/back/status",data)
}
// 后台-编辑小组
const groupUpdate = (data: any) => {
  return request.post("/api/group/back/update",data)
}
// 后台-小组列表(全部)
const groupListAll = (data: any) => {
  return request.post("/api/group/back/all",data)
}
// 后台-动态列表
const dynamicList = (data: any) => {
  return request.post("/api/dynamic/backend/list",data)
}
// 后台-删除动态
const dynamicDelete = (data: any) => {
  return request.get(`/api/dynamic/backend/delete?id=${data}`,data)
}
// 后台-评论列表
const commentBackList = (data: any) => {
  return request.post("/api/dynamic/backend/comment/list",data)
}
// 后台-删除评论
const commentDeletes = (data: any) => {
  return request.get(`/api/dynamic/backend/comment/delete?id=${data}`,data)
}




export default {
  getManagerDetail,
  getQueryFuzzy,
  contactRootNote,
  contactNote,
  selectedUsers,
  selectNames,
  groupList,
  addGroup,
  groupStatus,
  groupBackDetail,
  groupUpdate,
  groupListAll,
  dynamicList,
  dynamicDelete,
  commentBackList,
  commentDeletes,
}
