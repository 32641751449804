import router from '@/router'
import { Toast } from 'vant'
import api from '@/api'
// const dcmObj = (window as any).dcm
declare const window: any;
const __USERCENTER__ = (window as any).__USERCENTER__
// dcm 初始化
import plateform from './get-plateform'
import { useRoute, useRouter } from 'vue-router';
import { createPinia } from 'pinia';
const pinia = createPinia();

import useStore from "@/store"
const store = useStore(pinia)

const route = useRoute();
const Router = useRouter();
const DCM = {
  dcmAppId: () => {
    return 'qH52pAmHRK_c9w3yefgg5' // 测试
  },
  configOptions: () => {
    const origin = window.location.origin.includes('hilook.com.cn'); // 目前higroup-ihaier.hilook.com.cn为正式环境
    if (!origin) {
      return { // 测试环境
        "clientId": "K2b97a027d08523a8",
        "ssoUrl": "https://iam-test.haier.net",
        "appId": "cli_a31d4ad225fad013",
        "tokenUrl": "/api/user/ihaier/login"
        // "tokenUrl": "http://higroup-api.lanbenzi.cn/api/user/ihaier/login"
      }
    } else {
      return { // 正式环境
        "clientId": "Kf7bb83871c58e5bc",
        "ssoUrl": "https://iama.haier.net",
        "appId": "cli_a31d5ed5097b100b",
        "tokenUrl": "/api/user/ihaier/login"
        // "tokenUrl": "https://group.hilook.com.cn/api/user/ihaier/login"
      }
    }
  },
  checkAuth: async (path) => {
    const userStore = useStore();
    await userStore.getInfo()
    const staffId = JSON.parse(localStorage.getItem('userInfo')).staffId;
    const userList = window.limitUserList
    if (userList.indexOf(staffId) != -1 && window.location.pathname != '/mobile/no/permission/36') {
      const url = window.location.origin + '/mobile/no/permission/36?back=1'
      window.location.replace(url)
      return
    }

    const finalUrl = encodeURIComponent(window.location.origin + path)
    console.log('鉴权url', finalUrl, window.location.origin + path)
    DCM.getFeishuSignPackages({ url: finalUrl }).then(res => {
      // DCM.iHaierSetMainNavbar()
      console.log('使用ihaier主导航');
      // DCM.iHaierSetNavBar()
      console.log('设置ihaier导航栏');
      console.log('我是鉴权成功', res);
      console.log('dcm.ts line 98');

    })
  },
  // iHaier web登录
  iHaierLogin: (path?) => {
    const configOptions = DCM.configOptions();
    console.log('window.location.origin', window.location.origin);
    console.log('Ihaier调用参数', configOptions, window.h5sdk, window.tt);
    console.log('__USERCENTER__1', window.__USERCENTER__, __USERCENTER__);
    return new Promise((resolve, reject) => {
      __USERCENTER__.configUserCenter({
        ...configOptions,
        onTokenChange: token => {
          console.log('token进来了');

          if (token) {
            console.log('token', token);
          }
        },
        onUserInfoChange: userInfo => {
          console.log('userInfo进来了');
          if (userInfo) {
            console.log('userInfo', userInfo);
          }
        }
      });
      __USERCENTER__.login().then(async res => {
        console.log('__USERCENTER__', res);
        // #ifdef H5
        if (res.success) {
          localStorage.setItem('iHaierToken', res.token);
          
          DCM.checkAuth(path)
          const userStore = useStore();
          await userStore.getInfo()
          resolve(res)
        } else {
          resolve(res)
        }
        // #endif
      }).catch(err => {
        resolve(err)
        // reject(err)
      });
    })
  },
  getToken: (path?) => {
    return new Promise((resolve, reject) => {
      DCM.iHaierLogin(path).then(res => {
        console.log('首次进入', res);
        console.log('dcm.ts line 136');
        resolve(true)
      })
    })
  },
  share: (options) => {
    return new Promise((resolve, reject) => {
      window.tt.share({
        channelType: [
          // "wx",
          // "wx_timeline",
          "system"
        ],
        contentType: "url",
        title: options.title,
        url: options.url,
        success(res) {
          console.log('分享成功', JSON.stringify(res));
          resolve(true)
        },
        fail(res) {
          console.log(`share fail: ${JSON.stringify(res)}`);
          reject(false)
        }
      });
    })
  },
  /**
   * 调用ihaier上的分享到对话
   * @param options 
   * url: 分享的url
   * title: 分享的标题
   * image: 分享配图的url
   * content：描述文本，最多展示三行
   * @returns 
   */
  ihaierShare: options => {
    return new Promise((resolve) => {
      window.h5sdk.ready(() => {
        window.h5sdk.biz.util.share({
          ...options,
          onSuccess: function (result) {
            console.log(result, 'result');
            resolve(1)
          }
        });
      })
    })
  },
  // 打开新页面
  dcmOpenNewContainer: (options) => {
    // // iHaier中打开
    console.log('从iHaier中打开新页面', options);
    if (options.url.indexOf('fromihaiermsg=true') !== -1 && !plateform.isIHaierMobile()) {
      let url = options.url
      if (location.href.indexOf('hilook.com.cn') > -1) {
        url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a31d5ed5097b100b&lk_target_url=${encodeURIComponent(url)}`
      } else {
        url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a31d4ad225fad013&lk_target_url=${encodeURIComponent(url)}`
      }
      window.open(url)
      return 
    } 
    window.h5sdk && window.h5sdk.ready(function () {
      window.tt.openSchema({
        schema: options.url,
        external: false,
        success(res) {
          console.log('打开新页面成功', res);
        },
        fail(res) {
          console.log('打开新页面失败', `${JSON.stringify(res)}`);
        }
      });
    })
  },
  // 飞书鉴权 
  getFeishuSignPackages: async (urlData) => {
    try {
      const res = await api.getFeishuSignPackage(urlData);
      return new Promise((resolve, reject) => {
        window.h5sdk && window.h5sdk.config({
          appId: res.data.appId,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
          jsApiList: ['user_profile'],
          //鉴权成功回调
          onSuccess: (res) => {
            console.log(`飞书鉴权成功config success: ${JSON.stringify(res)}`);
            resolve(res)
          },
          //鉴权失败回调
          onFail: (err) => {
            reject(err)
            throw `飞书鉴权失败1config failed: ${JSON.stringify(err)}`;
          },
        });
      })

    } catch (error) {
      console.log('飞书鉴权失败2');
    }
  },
  // 关闭当前页面
  dcmCloseCurrentContainer: () => {
    window.h5sdk.ready(() => {
      window.tt.closeWindow({
        fail(res) {
          console.log(`closeWindow fail: ${JSON.stringify(res)}`);
        }
      });
    })
  },
  // 设置主导航栏
  iHaierSetMainNavbar: (xiaoxiUrl?: string) => {
    if (plateform.isIHaier()) {
      console.log('进入设置导航栏');
      try {
        window.h5sdk.ready(() => {
          window.tt.setMainNavRightItems({
            // 最多三个，左侧开始
            items: [{
              "iconURL": "http://hinews-prod.oss-cn-beijing.aliyuncs.com/2023-02-06-WdiW66-1675675245496.png",
              "id": "sousuo" // 字符串
            }, {
              "iconURL": xiaoxiUrl ? xiaoxiUrl : "http://hinews-prod.oss-cn-beijing.aliyuncs.com/2023-02-06-WmRbQb-1675675292832.png",
              "id": "xiaoxi" // 字符串
            },
            {
              "iconURL": "http://hinews-prod.oss-cn-beijing.aliyuncs.com/2023-02-06-kJ3Djz-1675675315342.png",
              "id": "gerenkongjian" // 字符串 
            }],
            success: function (data) {
              // 图片设置成功
              console.log('setMainNavRightItems onSuccess', data)
            },
            fail: function (err) {
              // 非主导航场景走这个
              console.log('setMainNavRightItems onFail', err);
              // catchError({
              // 	type: '设置主导航 setMainNavRightItems',
              // 	reason: err
              // })
            }
          });
          window.tt.onMainNavRightItemClick((res) => {
            switch (res.id) {
              case "sousuo":
                console.log("点击了搜索");
                DCM.dcmOpenNewContainer({
                  url: `${window.location.origin}/mobile/group/search/36?op_platform_service=hide_navigator`
                })
                break;
              case "xiaoxi":
                console.log("点击了消息");
                store.setPostsItem({});
                setTimeout(() => {
                  store.noticeNum = 0
                }, 200)
                store.throttle = false;
                DCM.dcmOpenNewContainer({
                  url: `${window.location.origin}/mobile/group/message/36?op_platform_service=hide_navigator`
                })
                if (store.throttle) return;
                store.throttle = true;
                break;
              case "gerenkongjian":
                console.log("点击了个人空间");
                // eslint-disable-next-line no-case-declarations
                const origin = window.location.origin.includes('hilook.com.cn')
                // eslint-disable-next-line no-case-declarations
                let _url = ''
                if (origin) {
                  _url = encodeURIComponent('https://xue.hilook.com.cn/#/pages/personalSpace/personalSpace?from=group')
                  _url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c7b57abb62d00e&lk_target_url=${_url}`
                } else {
                  _url = encodeURIComponent('https://haixueh5-test.lanbenzi.cn:8443/#/pages/personalSpace/personalSpace?from=group')
                  _url = `https://applink.feishu.cn/client/web_app/open?appId=cli_a3c4870b3039500e&lk_target_url=${_url}`
                }
                DCM.dcmOpenNewContainer({
                  url: _url
                })
                break;
            }
          });
        })

      } catch (e) {
        //TODO handle the exception
        console.log('setMainNavRightItems catch', e)
      }

    }
  },
  // 设置导航栏
  iHaierSetNavBar: () => {
    if (plateform.get() === 5) {
      console.log('设置导航栏');
      try {
        window.tt.setNavigationBar({
          // left: {
          // 	items: []
          // },
          right: {
            items: []
          },
          success: (res) => {
            console.log("setNavigationBar success");
          },
          fail: (err) => {
            console.log(`setNavigationBar fail ${JSON.stringify(err)}`);
            // catchError({
            // 	type: '设置导航栏 setNavigationBar',
            // 	reason: err
            // })
          }
        })
      } catch (e) {
        //TODO handle the exception
        console.log(111, e)
      }

    }
  },
  // 图片预览
  iHaierImagePreview: (urls, index) => {
    const i = index === undefined ? 0 : index
    console.log('index-i:', index, i)
    window.tt && window.tt.previewImage({
      urls,
      current: urls[i],
      success(res) {
        console.log(JSON.stringify(res))
      },
      fail(res) {
        console.log(`previewImage fail: ${JSON.stringify(res)}`)
      }
    })
  },
  // 分享纯图无文字描述,无链接跳转
  sendCardOnlyImg: (options) => {
    console.log('卡片的options：', options)
    return new Promise((resolve, reject) => {

      api.getImgKey(options.imgUrl).then(res => {

        window.h5sdk.ready(() => {
          window.tt.sendMessageCard({
            "shouldChooseChat": true,
            "chooseChatParams": {
              "selectType": 0,
              "multiSelect": true,
              "ignoreBot": true
            },
            "cardContent": {
              "msg_type": "interactive",
              "update_multi": true,
              "card": {
                "elements": [
                  {
                    "alt": {
                      "content": "",
                      "tag": "plain_text"
                    },
                    "img_key": res.data,
                    "tag": "img"
                  }
                ]
              }
            },
            success(res) {
              console.log('sendMessageCard:ok');
              resolve(res)
            },
            fail(res) {
              console.log(`sendMessageCard fail: ${JSON.stringify(res)}`);
              reject(res)
            }
          });
        })

      }).catch(err => {
        console.log('获取图片imagekey err:', err)
        if (err.code == 10001 && err.msg == "文件上传失败") {
          Toast('图片超过10M，无法分享')
        }
        reject(err)
      })

    })
  },
  //ihaier选人组件
  ihaierSelectComponent: (choosenIds = [], ChooseDepartment = false, maxNum = 1000, disableChosenIds = [], ignore = false) => {
    return new Promise((resolve, reject) => {
      window.h5sdk.ready(() => {
        window.tt.chooseContact({
          multi: true,
          ignore: ignore,
          maxNum: maxNum,
          enableChooseDepartment: ChooseDepartment, // 是否支持部门
          choosenIds: choosenIds,
          limitTips: '选择联系人数量已超过上限',
          externalContact: false,
          disableChosenIds: disableChosenIds,
          success(res) {
            console.log(`联系人列表`, res);
            resolve(res);
            // 取消焦点
          },
          fail(res) {
            console.log(`获取联系人失败`, res);
            // reject(data);
          }
        });
      })
    });
  },
  dcmOnlyShare: options => {
    console.log('dcmFunc.js dcmOnlyShare', options)
    if (plateform.isIHaier()) {
      const url = options.url || window.location.href;
      const needResult = options.needResult
      options = {
        url: url,
        "title": options.title,
        "content": options.description,
        "image": options.icon ||
          'http://hinews-prod.oss-cn-beijing.aliyuncs.com/xuehai-2023-01-05-ryChnR-1672881247331.png'
      };
      return new Promise((resolve, reject) => {
        window.h5sdk.ready(() => {
          window.h5sdk.biz.util.share({
            ...options,
            onSuccess: function (result) {
              console.log(result);
              if(needResult){
                resolve(result);
              }
            },
          });
        })
      })
    } else{
      console.log('请在客户端内打开')
    }
  }
}

export default DCM


